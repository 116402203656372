.ql-container {
  border-radius: 0px 0px 20px 20px;
  border: 1px solid #e7e7ee;
  background-color: #fff;
}

.ql-toolbar {
  border: 1px solid #e7e7ee;
  border-radius: 20px 20px 0px 0px;
}

.ql-editor {
  min-height: 200px;
}

.tmplt-body img {
  /* width: 200px; */
}

.menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.variables-menu {
  position: absolute;
  top: 25px;
  left: 0px;
  background-color: #ffffff;
  border-radius: 6px;
  z-index: 2000;
  box-shadow: 2px 2px 5px #00000040;
  transition: 300ms;
  opacity: 0;
  height: 0;
  padding: 4px 10px;
  overflow: hidden;
  overflow: hidden;
  text-wrap: nowrap;
}

.open-variables-menu {
  height: auto;
  opacity: 1;
}

.custom-menu-btn {
  font-size: 14px;
  cursor: pointer;
}

.custom-menu-btn:hover {
  color: #6123ff;
}

.variables-menu-item {
  padding: 5px 0px;
  text-align: left;
}

.variables-menu-item:hover {
  background-color: #6123ff30;
}

#toolbar {
  position: relative;
}

.ai-draft-form {
  position: absolute;
  top: 30px;
  left: 30px;
  width: calc(100% - 60px);
  z-index: 2000;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 2px 2px 5px #00000040;
  padding: 15px;
}

.ai-draft-form-loading-button .MuiLoadingButton-loadingIndicator {
  margin-top: 4px;
}

.ai-draft-form-loading-button svg circle {
  stroke: #ffffff;
}

.ai-draft-form-input:hover .MuiOutlinedInput-notchedOutline {
  border-color: rgb(97 35 255 / 26%) !important;
}

.ql-snow.ql-toolbar button:hover,
.ql-snow .ql-toolbar button:hover,
.ql-snow.ql-toolbar button:focus,
.ql-snow .ql-toolbar button:focus,
.ql-snow.ql-toolbar button.ql-active,
.ql-snow .ql-toolbar button.ql-active,
.ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow .ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow .ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow .ql-toolbar .ql-picker-item:hover,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: #6123ff;
}

.ql-snow.ql-toolbar button.MuiButton-contained:hover {
  color: #ffffff;
  background: #7a46fd !important;
}

.ql-snow.ql-toolbar button.MuiButton-outlined:hover {
  color: #7a46fd;
  background: #7a46fd10 !important;
}

.ql-snow.ql-toolbar button:hover .ql-stroke,
.ql-snow .ql-toolbar button:hover .ql-stroke,
.ql-snow.ql-toolbar button:focus .ql-stroke,
.ql-snow .ql-toolbar button:focus .ql-stroke,
.ql-snow.ql-toolbar button.ql-active .ql-stroke,
.ql-snow .ql-toolbar button.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow.ql-toolbar button:hover .ql-stroke-miter,
.ql-snow .ql-toolbar button:hover .ql-stroke-miter,
.ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.ql-snow .ql-toolbar button:focus .ql-stroke-miter,
.ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: #6123ff;
}

.suggestion-element {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #eeeeee;
  cursor: pointer;
}

.suggestion-element:hover {
  background-color: #6123ff10;
}