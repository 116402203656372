.box-r-24 {
  box-shadow:
    0px 37px 81px 0px #00000003,
    0px 148px 148px 0px #00000003,
    0px 333px 200px 0px #00000003,
    0px 593px 237px 0px #00000000,
    0px 926px 259px 0px #00000000;
  background-color: #ffffff;
  margin-bottom: 16px;
  border-radius: 24px;
}

.box-r-24-content {
  padding: 24px;
}

.box-r-24-divider {
  width: 100%;
  height: 1px;
  background: #e7e8ea;
}

.file-elements {
  gap: 10px;
  margin: 20px 0px;
}

.file-element {
  width: 100%;
  padding: 8px 16px;
  border-radius: 8px;
  background-color: #6123ff0d;
}

.file-element .delete-icon {
  cursor: pointer;
}

.file-element .delete-icon:hover {
  opacity: 0.8;
}
