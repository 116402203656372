.rsw-editor {
  border: none;
  max-height: 220px;
  overflow: auto;
}

.rsw-toolbar {
  padding-bottom: 0;
  padding-top: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: revert;
  font-weight: revert;
}

pre {
  max-width: 400px;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  overflow: auto;
  font-family: 'Courier New', Courier, monospace;
  font-size: 14px;
  color: #333;
}

.tmplt-body img {
  /* width: 200px; */
}